export const GAME_CONFIGURATION = 'game_configuration';
export const GAME_TO_START = 'to_start';
export const GAME_IN_PROGRESS = 'in_progress';
export const GAME_OVER = 'over';

export const GAME_STATUS_LIST = [
  GAME_TO_START,
  GAME_IN_PROGRESS,
  GAME_OVER,
  GAME_CONFIGURATION
];
