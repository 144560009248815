import {API} from "@/utils/api";
import {isNull} from "@/utils/isNull";
import Router, {
  ROUTE_GAME,
  ROUTE_HOME, ROUTE_ROOM_ACCOUNT,
  ROUTE_ROOM_CONFIGURATION,
  ROUTE_ROOM_CREATION,
  ROUTE_ROOM_TEAM
} from '../router';
import {store} from "@/store/store";
import {ADD_USER_ROOM, REMOVE_USER_ROOM, UPDATE_USER_SOCKET} from "@/store/modules/userModule/actions";
import {GAME_CONFIGURATION, GAME_IN_PROGRESS, GAME_OVER, GAME_TO_START} from "../constants/games/GameStatusConstants";
import {GAME_TYPE_MULTI, GAME_TYPE_SOLO, GAME_TYPE_VS} from "../constants/games/GameTypes";
import i18n from '../i18n/i18n';
import Vue from "vue";
import {TOAST_ERROR} from "../constants/toast/toastConstants";
import {ROOM_STATUS_ON} from "../constants/rooms/RoomStatus";

class RoomConfigurationService {

  async createRoom() {
    if (store.getters.isNotConnectedOrIsGuest) {
      await Router.push({name: ROUTE_ROOM_ACCOUNT.name, query: {redirect: ROUTE_ROOM_CREATION.name}});
      return;
    }
    await Router.push({name: ROUTE_ROOM_CREATION.name});
  }
  /**
     * Add user to a ROOM and update socket
     *
     * @param pinRoom
     * @param user
     * @returns {Promise<void>}
     */
  async addUserIntoRoom(pinRoom, user) {
    if (isNull(user.id)) {
      return;
    }

    let room;
    await API.get('/room', {params: {pin: pinRoom, status: ROOM_STATUS_ON}})
      .then((response) => room = response.data)
      .catch(({response}) => Vue.$toast.error(i18n.t(`room.errors.${response.data.code}`).toString(), TOAST_ERROR));

    await API.put(`/user/${user.id}/me`, {
      roomId: room.id
    }).then(() => {
      store.dispatch(ADD_USER_ROOM, room.id);

      switch (user.room_state) {
      case GAME_CONFIGURATION:
        Router.push({name: ROUTE_ROOM_CONFIGURATION.name, params: {pin: pinRoom}})
          .catch(() => {});
        break;
      case GAME_TO_START:
        Router.push({name: ROUTE_ROOM_TEAM.name, params: {pin: pinRoom}})
          .catch(() => {});
        break;
      case GAME_IN_PROGRESS:
        Router.push({name: ROUTE_GAME.name, params: {pin: pinRoom}})
          .catch();
        break;
      case GAME_OVER:
        store.dispatch(REMOVE_USER_ROOM);
        Router.push({name: ROUTE_HOME.name})
          .catch(() => {});
        break;
      case null:
      default:
        Router.push({name: ROUTE_HOME.name})
          .catch(() => {});
        break;
      }

    });
  }

  /**
     * Return a room with all his users
     *
     * @returns {Promise<Room>}
     */
  async getRoom(pin) {
    return await API.get(`/room`, {
      params: {
        pin: pin
      }
    })
      .then((room) => {
        // Transform GAMETYPE into our objects
        room.data.gameType = room.data.gameType === GAME_TYPE_VS.type
          ? GAME_TYPE_VS : room.data.gameType === GAME_TYPE_MULTI.type
            ? GAME_TYPE_MULTI : GAME_TYPE_SOLO;

        // Transform Room for room settings
        room.data.gameSettings = {
          closeAfter: room.data.closeAfter,
          saveResult: room.data.saveResult
        };

        return room.data;
      })
      .catch(({response}) => Vue.$toast.error(i18n.t(`room.errors.${response.data.code}`).toString(), TOAST_ERROR));
  }

  /**
     * Get all users in specific room
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
  async getUsersInRoom(id) {
    return await API.get('/user/me', {
      params: {
        room: id
      }
    }).then(res => res.data);
  }

  /**
     * Get all bundles
     * @returns {Promise<AxiosResponse<any>>}
     */
  async getBundles() {
    return await API.get(`bundles`).then(res => res.data.results);
  }

  /**
     * Update room entity when the configuration change
     *
     * @param socket
     * @param criteria
     * @param body
     * @returns {Promise<void>}
     */
  async updateRoomConfiguration(criteria, body, socket) {
    await API.put('room', body, {params: criteria})
      .then(response => response.data);

    socket.emit('changeRoomConfiguration', {pin: criteria.pin});
  }

  /**
     * Update user socket
     *
     * @param socket
     * @param user
     * @param pin
     */
  async connectUserToSocketRoom(socket, user, pin) {
    await API.put(`/user/${user.id}/me`, {
      socketId: socket.id,
    });
    socket.emit('connectUserToRoom', {
      pin: pin
    });
    store.dispatch(UPDATE_USER_SOCKET, socket.id);
  }

  clearTimeout(socket) {
    socket.emit('clearTimeout');
  }

  /**
     * Remove a user from a Room AND from the STORE
     *
     * @param socket
     * @param user
     * @param pin
     * @returns {Promise<void>}
     */
  async disconnectRoom(socket, user, pin) {
    await API.put(`/user/${user.id}/remove-room`, {});
    socket.emit('disconnectUserFromRoom', {
      pin: pin
    });
    store.dispatch(REMOVE_USER_ROOM);
    await Router.push({path: ROUTE_HOME.path});
  }

  /**
   * Resync socket
   * @returns {Promise<void>}
   */
  async resyncSocket(socket, user) {
    await API.put(`/user/${user.id}/me`, {
      socketId: socket.id,
    });
  }

  /**
     * Launch the game
     *
     * @param owner
     * @param room
     * @param pin
     * @param socket
     * @returns {Promise<void>}
     */
  async launchGame(owner, room, pin, socket) {
    // Create game with empty teams
    const res = await API.post('game/with-teams', {
      ownerId: owner.id,
      type: room.gameType.type,
      status: GAME_TO_START,
      bundle: room.bundle,
      nbTeam: room.gameType.type === GAME_TYPE_VS.type ? 2 : 1
    });
    const game = res.data.game;
    const teams = res.data.teams;

    // Populate team if not vs
    if (room.gameType.type !== GAME_TYPE_VS.type) {
      // Set user teams
      for (const user of room.users) {
        await API.put(`/user/${user.id}/me`, {
          team: teams[0].id,
        });
      }

      // Create game users
      await API.post('game-users', {
        gameId: game.id,
        teamsId: teams.map(team => team.id),
        usersId: room.users.map(user => user.id)
      });
    }

    // Update room
    await API.put('room', {gameId: game.id}, {params: {id: room.id}});

    socket.emit('launchGame', { pin: pin, room: room });
  }

  /**
     * Check if room has minimum playable player
     *
     * @param players
     * @returns {string|null}
     */
  checkMinimumPlayers(players) {
    return players.length < 2 ? i18n.t('games.game_configurations.errors.minimum_players').toString() : null;
  }

  /**
     * Check if a bundle has been chosen
     *
     * @param bundle
     * @returns {string|null}
     */
  checkIfBundleChoosen(bundle) {
    return isNull(bundle) ? i18n.t('games.game_configurations.errors.bundle_not_choosen').toString() : null;
  }

  /**
     * Check if error object not empty
     * @param errors
     * @returns {unknown}
     */
  hasErrors(errors) {
    return Object.values(errors).find(value => value !== null);
  }
}

export default new RoomConfigurationService();
