import i18n from "@/i18n/i18n";

export const GAME_TYPE_MULTI = {
  type: 'multi',
  name: i18n.t('games.game_configurations.game_types.multi'),
  description: 'description',
  image: 'collaboratif.svg'
};

export const GAME_TYPE_SOLO = {
  type: 'solo',
  name: i18n.t('games.game_configurations.game_types.solo'),
  description: 'description',
  image: 'individuel.svg'
};

export const GAME_TYPE_VS = {
  type: 'vs',
  name: i18n.t('games.game_configurations.game_types.vs'),
  description: 'description',
  image: 'competitif.svg'
};

export const GAME_TYPES = [
  GAME_TYPE_MULTI,
  GAME_TYPE_VS,
  GAME_TYPE_SOLO
];
