<template>
  <Loading :msg="'Création de votre partie en cours, veuillez patienter.'" />
</template>

<script>
import {API} from "@/utils/api";
import Loading from "../../../components/loading/Loading.vue";
import RoomConfigurationService from "../../../services/roomConfigurationService";
import {ROUTE_HOME} from '@/router';
import {store} from "@/store/store";
import {UPDATE_ROOM_STATE} from "@/store/modules/userModule/actions";
import {GAME_CONFIGURATION} from "@/constants/games/GameStatusConstants";

export default {
  name: "RoomCreation",
  components: {Loading},
  async mounted() {
    try {
      await API.put('/room', {ownerId: null}, {
        params: {
          owner: this.$store.getters.getUser.id
        }}
      );
    } catch (_){
      // Here, we do not show any error message
      // If no room have been found, it means that the user wasn't associate to any.
    }

    try {
      const room = await API.post('room', {ownerId: this.$store.getters.getUser.id}).then(res => res.data);
      await store.dispatch(UPDATE_ROOM_STATE, GAME_CONFIGURATION);

      const user = store.getters.getUser;
      await RoomConfigurationService.connectUserToSocketRoom(this.$socket.client, user, room.pin);
      await RoomConfigurationService.addUserIntoRoom(room.pin, user);
    } catch (err) {
      // TODO - Redirect to home (voir Julien)
      await this.$router.push({ name: ROUTE_HOME.name });
    }
  }
};
</script>

<style scoped>
</style>
