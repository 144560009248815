<template>
  <div class="flex w-screen h-screen relative p-16">
    <div class="moon">
      <img
        alt="moon"
        src="https://cdn2.iconfinder.com/data/icons/thesquid-ink-40-free-flat-icon-pack/64/space-rocket-512.png"
        style="width:60px;height:60px;"
        class="loader"
      >
      <ul>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
    <div class="bg-space-green bg-opacity-60 p-2 rounded-full font-raleway-extra-bold absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-1xl text-white">
      <span class="">{{ msg }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ['msg']
};
</script>

<style scoped>

.loader {
  position:absolute;
  width:120px;
  height:80px;

  animation:infinite-spinning 6s infinite;
  animation-timing-function:linear;
  transform-origin:235% 210%;
}

@keyframes infinite-spinning {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.moon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -100px;
  margin-top: -150px;
  width: 200px;
  height: 200px;
  background: #c7cbd0;
  border-radius: 50%;
  box-shadow: inset -25px 0 0 0 #9098a1;
  transition: transform 0.2s ease-in-out;
}

.moon:hover {
  transform: scale(1.2);
}

li {
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
}
li:nth-child(1) {
  left: 25px;
  top: 60px;
  width: 50px;
  height: 50px;
  box-shadow: inset 6px -2px 0 0 #414043;
}
li:nth-child(2) {
  left: 150px;
  top: 50px;
  width: 25px;
  height: 25px;
  box-shadow: inset 3px -1px 0 0 #414043;
}

li:nth-child(3) {
  left: 100px;
  top: 150px;
  width: 25px;
  height: 25px;
  box-shadow: inset 3px -1px 0 0 #414043;
}

li:nth-child(4) {
  left: 50px;
  top: 150px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0 #414043;
}

li:nth-child(5) {
  left: 87.5px;
  top: 16.66666667px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0 #414043;
}

li:nth-child(6) {
  left: 114.28571429px;
  top: 80px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0 #414043;
}

li:nth-child(7) {
  left: 181.81818182px;
  top: 100px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0 #414043;
}
</style>
